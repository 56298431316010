import React from 'react';
import sanitize from 'sanitize-html';
import Avatar from '../../atoms/Avatar/Avatar';
import { useTranslation } from '@/src/i18n';
import { analyticsEvent } from '@/src/analytic_tag_manager';

type Props = {
  pictureS3Id?: string;
  speciality: string;
  objectID: string;
  city: string;
  isTlcAvailable?: boolean;
  centerId?: string;
  highlightedText?: any;
  isParamedical?: boolean;
  firstName?: string;
  lastName?: string;
};

const PractitionerItem = ({
  pictureS3Id,
  speciality,
  objectID,
  isTlcAvailable,
  city,
  highlightedText,
  isParamedical,
  firstName,
  lastName,
}: Props) => {
  const { t } = useTranslation();
  const paramedicalTitle =
    isParamedical === false ? t('professional_title') : '';

  return (
    <div
      className={`search-item item--prat item-${objectID} `}
      onClick={() => {
        analyticsEvent({
          category: 'SearchForm',
          action: 'ClickPractitioner',
          label: `${firstName}${lastName}_${speciality}-${city}`,
        });

        analyticsEvent({
          category: 'Search',
          action: 'Search',
          label: 'practitioner',
        });
      }}
    >
      <Avatar
        {...(pictureS3Id ? { source: `/files/${pictureS3Id}` } : {})}
        isOnline={isTlcAvailable}
      />
      <div className="wrapper-info">
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: sanitize(paramedicalTitle + highlightedText.join(' '), {
              allowedTags: ['em'],
            }),
          }}
        />
        <p className="info">
          {`${speciality}`} , <span>{`${city}`}</span>
        </p>
      </div>
    </div>
  );
};

export default PractitionerItem;
